<template>
  <div class="privacy">
    <!-- header -->
    <nav-header />
    <!-- content -->
    <div class="privacy-container">
      <div class="container-inner">
        <div class="l-title">PDF在线转换用户服务协议</div>
        <div class="l-version">更新时间：2022年7月4日</div>
        <div class="l-text">欢迎您使用PDF在线转换！为了给您提供更好的服务，在使用PDF在线转换服务前，请仔细阅读《PDF在线转换用户服务协议》（以下简称“本协议”）、《PDF在线转换隐私保护政策》以及其他任何与PDF在线转换之间有关的协议，并充分理解协议的全部内容。</div>
        <div class="l-text">
          本协议是您（以下或称“用户”）与PDF在线转换运营方上海琴傲信息软件有限公司（以下简称“琴傲信息”或“我们”）就您关于PDF在线转换注册、登录、使用等与PDF在线转换有关所有一切行为的所订立的权利义务规范。
          <span class="t_bold t-d">对于本协议中加粗的条款，用户应当重点阅读并充分理解。如用户不同意本协议或其中任何条款约定，用户应立即停止注册。</span>
        </div>
        <div class="l-text">
          我们将按照本协议的有关条款为用户提供产品服务。
          <span class="t_bold t-d">当您点击同意本协议即表示您同意并接受本协议的全部内容，愿意遵守本协议及PDF在线转换平台公示的各项规则、规范的全部内容，若您不同意则可停止注册、登录或使用PDF在线转换平台的相关服务。</span>
          如用户对本协议以及本协议的修改有任何意见，可通过尾页联系方式向我们提出，我们会积极重视用户的意见，在我们回复之前，请用户不要访问或使用PDF在线转换的相关服务。
        </div>
        <div class="l-t-title">一、本产品及相关服务</div>
        <div class="l-text">1. PDF在线转换为一款由琴傲信息运营的文档转化工具软件，支持多种文件格式与PDF互转，包括Word(*.docx)，Word(97-2003)(*.doc)， PowerPoint(*.pptx)，Excel(*.xlsx)，Excel(97-2003)(*.xls)，JPEG(*.jpg)， PNG(*.png)等格式的文档转化，支持一键批量转换，以及提供PDF文档阅读、注释、水印、 加密等服务。</div>
        <div class="l-text">2. 我们可能为不同的终端设备开发不同的版本，您应当根据实际设备状况获取、下载、安装合适的版本。您可根据需要自行使用本产品及相关服务或更新版本，如您不再需要使用本产品及相关服务，您也可自行卸载本软件。`  </div>
        <div class="l-text">3. 您知悉并认可，我们会对您在线登录使用本产品的设备数量、异地登录频次设置上限。<span class="t_bold t-d">如您的账号存在频繁、多次异地登录的情况，我们有权视情况冻结该账号。</span></div>
        <div class="l-t-title">二、账户注册</div>
        <div class="l-text">1. 账号类型</div>
        <div class="l-text">（1）注册账号：您可以使用归属于您本人的手机号进行注册或者通过您的微信或QQ号进行账号注册，获得注册账号。注册账号更加有助于您使用我们的产品和服务。</div>
        <div class="l-text">（2）您理解并承诺，您所设置的帐号不得违反国家法律法规及琴傲信息的相关规则，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为，您的帐号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设帐号，不得恶意注册抖你帐号（包括但不限于频繁注册、批量注册帐号等行为）。琴傲信息有权对您提交的信息进行审核。</div>
        <div class="l-text t_bold">2. 账户注册条件：</div>
        <div class="l-text t_bold t-d">（1）如您为成年人（即18周岁以上）、法人或其他组织，您应具有完全民事行为能力；</div>
        <div class="l-text t_bold t-d">（2）如果您不具备完全民事行为能力，您在注册、使用PDF在线转换前，应事先取得监护人的同意。特别的，如您为未成年人（即未满18周岁，下同），还应遵守本协议有关未成年人使用条款的规定。</div>
        <div class="l-text t_bold t-d">（3）如您不符合上述任一条件，请您立即停止注册或使用琴傲信息的服务，否则琴傲信息有权随时暂停或终止您的用户资格。</div>
        <div class="l-text">3. 完成注册：</div>
        <div class="l-text">（1）您应当按照注册页面的提示，提供真实、完整、准确的信息，您不得使用他人的信息注册账户或向琴傲信息提供其他的虚假信息。</div>
        <div class="l-text">（2）PDF在线转换账户的所有权归琴傲信息所有，您完成申请注册后，仅获得账户的使用权。</div>
        <div class="l-text t_bold">4. 信息真实性保证：</div>
        <div class="l-text t_bold t-d">（1）如发现您以虚假或不实信息骗取注册，琴傲信息有权不经通知单方采取限期改正、删除资料、限制或暂停使用、冻结或注销账户并终止对您提供部分或全部服务等措施。</div>
        <div class="l-text t_bold t-d">（2）您应及时更正不实信息，更新、完善您的相关信息。</div>
        <div class="l-text t_bold t-d">（3）如您未及时更正、更新信息导致琴傲信息无法按协议约定向您提供服务，或导致您在使用琴傲信息产品或服务过程中产生任何损失，应由您自行承担。</div>
        <div class="l-text t_bold t-d">（4）琴傲信息对以上原因造成的服务暂停、终止不承担任何责任，您应当承担一切不利后果。如因此给琴傲信息造成损失的，您应当承担相应的责任。</div>
        <div class="l-t-title">三、账户安全及使用规范</div>
        <div class="l-text">
          1. 您应当妥善保管自己的账号、密码及其他有效识别信息。因您个人原因导致的账号、密码或其他有效识别信息泄露、丢失或账号和密码被窃取、篡改等导致的一切损失，由您自行承担。
          <span class="t_bold t-d">您须对使用账号、密码所采取的一切行为负责，琴傲信息不承担任何责任。</span>
        </div>
        <div class="l-text">
          2. 如您发现账号、密码被他人冒用或盗用，请立即以有效方式通知琴傲信息，要求琴傲信息暂停服务。
          <span class="t_bold t-d">琴傲信息在采取行动的合理期限内，不对您的损失承担任何责任。</span>
        </div>
        <div class="l-text">
          3. 您不得将账号、密码以及部分或全部的账户权益转让、出售、出租、出借或分享给他人。
          <span class="t_bold t-d">如因您将账号、密码以及部分或全部的账户权益转让、出售、出租、出借或分享给他人，导致侵犯或可能侵犯琴傲信息的合法权益或者第三方的个人信息、隐私权等其他合法权益，琴傲信息有权不经通知而限制、冻结、注销您的账户，暂停或终止向您提供本协议项下的部分或全部服务，且无需对为此承担任何责任。</span>
        </div>
        <div class="l-text">4. 通过您的账户上传、制作、分享的任何信息或作出的任何行为都将视为您本人的行为，您应对该账户下发生所有行为负全部责任。</div>
        <div class="l-text">
          5. 请您在申请注销手机号或者手机号因其他原因被注销的时候，及时更新PDF在线转换账号绑定的手机号信息。
          <span class="t_bold t-d">如因您不及时更新PDF在线转换账号绑定的手机号信息而导致用户数据泄露或其他个人权益受损，琴傲信息不承担任何责任。</span>
        </div>
        <div class="l-t-title">四、软件及服务使用规范</div>
        <div class="l-text">1. 琴傲信息给予您一项不可转让、可撤销及非排他性的许可，以使用我们的软件及服务。除另有书面约定，您仅可以为非商业目的在相应的终端设备上安装、使用、运行我们的软件及服务。</div>
        <div class="l-text">
          2. 您可以直接从琴傲信息的网站上获取我们的软件，也可以从得到琴傲信息授权的第三方处获取。
          <span class="t_bold t-d">如果您从未经琴傲信息授权的第三方获取我们的软件或与该等软件名称相同的安装程序，我们无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。</span>
        </div>
        <div class="l-text">3. 您理解并同意，您不得利用PDF在线转换上传、制作、分享、传播含有如下内容的信息：</div>
        <div class="l-text">（1）反对中华人民共和国宪法所确定的基本原则的；</div>
        <div class="l-text">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
        <div class="l-text">（3） 损害国家荣誉和利益的；</div>
        <div class="l-text">（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</div>
        <div class="l-text">（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</div>
        <div class="l-text">（6）煽动民族仇恨、民族歧视，破坏民族团结的；</div>
        <div class="l-text">（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
        <div class="l-text">（8）散布谣言，扰乱经济秩序和社会秩序的；</div>
        <div class="l-text">（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
        <div class="l-text">（10）侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</div>
        <div class="l-text">（11）法律、行政法规禁止的其他内容。</div>
        <div class="l-text t_bold">4. 琴傲信息有权基于单方独立判断，在认为可能发生危害国家安全、公共安全、社会公共利益、琴傲信息或第三方合法权益等情形时，不经通知而限制、冻结、注销您的账户，暂停或终止向您提供本协议项下的部分或全部服务，并将您的相关数据删除，且无需对您承担任何责任。该等情形包括但不限于：</div>
        <div class="l-text">（1）您为注册账户而提供的信息不具有真实性、完整性或准确性，包括但不限于盗用他人证件信息注册等；</div>
        <div class="l-text">（2）将账户转让、出售、出租、出借或分享给他人使用；</div>
        <div class="l-text">（3）盗取或非法访问、滥用其他用户账户或账户内的信息；</div>
        <div class="l-text">（4）删除我们的产品所有关于著作权的信息，对我们的产品或服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件源代码；</div>
        <div class="l-text">（5）对我们的产品进行商业性复制、更改、修改、挂接运行，或制作任何衍生作品或产品，使用插件、外挂或非经授权的工具接入、干扰、影响我们的产品或服务；</div>
        <div class="l-text">（6）限制、禁止或通过其它方法干扰、影响他人使用PDF在线转换，或干扰网络的正常运行，如发布或传输以下性质的任何信息或软件：包含蠕虫、病毒或其它有害功能，或生成的流量级别可能妨碍其他人使用、发送或检索信息；</div>
        <div class="l-text">（7）通过网络爬虫或其他自动方式访问或尝试访问我们的产品或服务，或违反我们的产品或服务中的robots.txt或类似文件；</div>
        <div class="l-text">（8）对我们的产品或服务相关信息内容等数据进行商业性使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</div>
        <div class="l-text">（9）出于恶意进行的任何操作，或可能损害针对我们的产品或服务的任何评级或评论，以及任何可能影响我们的产品或服务完整性或准确性的任何行为；</div>
        <div class="l-text">（10）侵犯或可能侵犯琴傲信息或任何第三方的知识产权、个人信息、隐私权或其他合法权益；</div>
        <div class="l-text">（11）从事违反法律或本协议的其他行为。</div>
        <div class="l-text t_bold t-d">5. 您使用PDF在线转换上传、制作、分享的任何内容（包括但不限于文档图片、文档信息或其他信息等，以下简称“内容”），无论是公开还是私下传送，均由您承担责任，您保证已拥有必要授权上传内容，不得侵犯他人的知识产权、个人信息、隐私权等合法权益。若因您的行为导致琴傲信息受到损失（包括但不限于损害赔偿、律师费），您同意对该损失承担全部赔偿责任。琴傲信息保留依法对内容进行审核、屏蔽、删除的权利或终止服务的权利。</div>
        <div class="l-text t_bold t-d">6. 如您在使用PDF在线转换的过程中，违反法律的规定或本协议的任何约定，导致琴傲信息、其他用户或任何第三方损失，您应当承担全部的责任，琴傲信息不为此承担任何责任。</div>
        <div class="l-t-title">五、 会员购买和充值规则</div>
        <div class="l-text">1.我们的某些功能或服务是以收费方式提供的，您要购买或充值后才能使用。请您在下单前，仔细确认所购的会员种类、服务名称、价格、数量等订单信息以及选择相应的支付方式，微信或支付宝等。</div>
        <div class="l-text">2. 当您提交订单信息并成功支付相应价款时，您与琴傲信息关于该产品或服务的买卖合同成立并生效。在合同成立并生效之前，您与琴傲信息皆有权取消该服务的订单。此处提请您注意，合同成立并生效是琴傲信息向您提供会员 服务的前提。</div>
        <div class="l-text t_bold t-d">3.琴傲信息可能根据实际需要对收费服务的收费标准、方式进行修改和变更。我们通过线上提供的功能或服务价格随时可能变更，琴傲信息不对功能或服务的降价或促销提供价格保护或退款。</div>
        <div class="l-text">4. 尽管琴傲信息会尽最大可能保障PDF在线转换所展示的功能或服务价格的准确性，但由于系统故障、人为失误等各种因素均可能导致价格显示异常。如因上述情形造成的功能或服务的价格异常，导致琴傲信息继续履行合同显失公平的，我们有权根据具体情况与您协商一致后，解除合同并返还您已支付的价款。</div>
        <div class="l-t-title">六、 信息存储及同步</div>
        <div class="l-text">
          1. 您了解并同意，使用PDF在线转换时相关信息将存储于您的终端设备中，占用您终端设备的存储空间。
          <span class="t_bold t-d">请您注意，我们不提供文档资料（包括转换前、转换后的文档）的存储服务，因此，我们建议您及时将文档资料（包括转换前、转换后的文档）保存到本地。由于您未保存而丢失，蓝色办公不承担任何责任。</span>
        </div>
        <div class="l-t-title">七、 账户注销</div>
        <div class="l-text">1. 如您的账户存在以下情形，琴傲信息有权注销您的账户：</div>
        <div class="l-text">（1） 您的行为违反法律规定或本协议的约定；</div>
        <div class="l-text">（2） 基于法律规定或国家有权的机关的要求；</div>
        <div class="l-text">（3） 您注册账户后，连续超过9个月未登录使用账户，同时您的账户内没有余额且无任何未到期服务或权益。</div>
        <div class="l-text">2. 如您向琴傲信息提出账户注销申请，当您的账户符合以下条件，经琴傲信息审核通过即可注销账户：</div>
        <div class="l-text">（1） 账户处于正常状态，不涉及任何纠纷或争议（包括投诉举报或被投诉举报），且未被有权机关采取限制措施；</div>
        <div class="l-text">（2） 账户内无未完成的交易；</div>
        <div class="l-text">（3） 账户内不存在其他未了结的权利义务（即账户内无余额且无任何尚未到期服务或权益）或因注销账户而产生纠纷的情况；</div>
        <div class="l-text">（4） 其他可能影响账户注销的情况。</div>
        <div class="l-text">3. 如需要注销账户，您可通过dannapdf@163.com与我们联系，申请注销账户。经过琴傲信息审核通过后，予以注销。</div>
        <div class="l-text t_bold t-d">4.在账户注销过程中，如果您的账户涉及纠纷或争议，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，琴傲信息有权中止或终止您账户的注销程序而无需另行得到您的同意。</div>
        <div class="l-text t_bold t-d">5. 您已充分理解并同意，账户注销后，您将无法再继续使用琴傲信息提供的服务，不再拥有账户相关的权益。法定期限届满后，账户相关的内容或数据将被删除或匿名化，且无法恢复。</div>
        <div class="l-text">6. 因注销账户所带来的不便或不利后果，由您自行承担。</div>
        <div class="l-t-title">八、数据保护</div>
        <div class="l-text">
          琴傲信息重视对用户信息的保护。
          <a href="/#/privacy">《PDF在线转换隐私政策》</a>
          呈现了琴傲信息将如何收集、使用、保存、共享及保护您的信息，详情请参阅
          <a href="/#/privacy">《PDF在线转换隐私政策》</a>
          。
        </div>
        <div class="l-t-title">九、风险提示</div>
        <div class="l-text t_bold t-d">1.琴傲信息将按现有技术水平，尽力保障琴傲信息服务的正确、持续、及时和安全，但我们对无法合理预知和不可控制的意外风险（如计算机病毒、木马或其他恶意程序、黑客攻击，设备、系统、硬件软件和通信线路故障、自然灾害等）、产品技术瑕疵、服务的稳定性以及由此产生的任何损失或损害不作任何形式的保证，亦不负任何赔偿责任，但法律另有规定的除外。</div>
        <div class="l-text t_bold t-d">2.我们的产品或服务可能会不时提供更新，您可以自主选择是否接受更新；如果您选择不更新，我们不保证向您提供最新的服务，由此可能带来的任何损失由您自行承担。</div>
        <div class="l-text t_bold t-d">3.在适用法律允许的范围内，琴傲信息有权随时自行决定暂停、终止、修改服务，或对部分用户暂停、终止、修改服务，且毋须另行通知。</div>
        <div class="l-text t_bold t-d">4.PDF在线转换可能会包含第三方平台或应用的链接、应用程序编程接口或软件开发工具包，琴傲信息提供该等链接的目的仅在于向您提供方便，琴傲信息对此不作任何保证，亦不代表琴傲信息对该等第三方、其产品及服务的任何推荐、支持或授权，您应当自行甄别并自担风险。您在使用上述第三方的任何产品和服务时，应当了解并接受该等第三方的条款和规则。</div>
        <div class="l-text t_bold t-d">5.因您自身的原因导致的任何损失或责任，由您自行负责，琴傲信息不承担责任。琴傲信息不承担责任的情形包括但不限于：</div>
        <div class="l-text t_bold t-d">（1） 您未按照本协议或琴傲信息不时公布的任何规则进行操作导致的任何损失或责任；</div>
        <div class="l-text t_bold t-d">（2） 您未向琴傲信息提供正确的联络方式，未能及时收到琴傲信息相关通知导致的任何损失或责任；</div>
        <div class="l-text t_bold t-d">（3） 其他因您自身原因导致的任何损失或责任。</div>
        <div class="l-t-title">十、知识产权的保护</div>
        <div class="l-text">1. 琴傲信息向您提供服务过程中使用的所有内容，包括但不限于任何文字、图像、视频、图表、软件、算法及其他材料均为琴傲信息或其他特别注明的权利人所有。琴傲信息及相应权利人并未通过本协议授予您对上述信息和内容的任何其他权利。除非经相应权利人事先书面同意，您不得以任何形式向任何第三方公开发表、传输、复制或以其他方式使用琴傲信息提供的部分或全部信息，不得更改、虚化或删除任何署名、商标、版权标识和/或其他权利声明。</div>
        <div class="l-text t_bold t-d">2. 尊重知识产权是您应尽的义务，如您有违反，造成琴傲信息或相应权利人损失的，您需要承担损害赔偿责任。</div>
        <div class="l-t-title">十一、关于未成年人的使用</div>
        <div class="l-text t_bold t-d">
          如果您是未成年人用户，并希望使用PDF在线转换，请征得您的监护人的同意，并在您的监护人的指导、监督下使用。此外，您的监护人有责任帮助您正确理解本协议、
          <a href="/#/privacy">《PDF在线转换隐私政策》</a>
          以及其它相关法律文件的内容，从而引导您正确使用PDF在线转换。
        </div>
        <div class="l-t-title">十二、商业活动</div>
        <div class="l-text">您理解并同意，我们可以在琴傲信息提供的产品或服务中进行下述商业活动：通过PDF在线转换、网站、小程序或手机短信、邮件或其他合法方式向您发送关于琴傲信息或第三方的商业性广告或其他相关商业信息。</div>
        <div class="l-text t_bold t-d">我们特别提醒您，如果您收到的推送信息是关于第三方而非琴傲信息的，对于此类信息，您应当自行判断其内容的真实性并为自己的判断行为负责。除法律明确规定外，您因此类信息提供的内容而遭受的任何损失或损害，我们均不承担任何责任。</div>
        <div class="l-t-title">十三、修改与变更</div>
        <div class="l-text">
          1. 琴傲信息有权根据法律法规、国家标准及业务运营之需要适时修改本协议。
          <span class="t_bold t-d">如果本协议发生变更的，我们将在PDF在线转换中公布更新后的协议。该等变更行为不视为琴傲信息违反本协议之约定。如您在本协议修改后继续使用PDF在线转换，即表示您已充分阅读、理解并同意修改后的协议，愿意受修改后协议的约束；如您不同意修改后的协议，请立即停止使用PDF在线转换。</span>
        </div>
        <div class="l-text">
          <span class="t_bold">2. 除本协议以外，我们已经发布的或将来可能发布的有关琴傲信息向您提供产品或服务的协议、服务规则、政策、须知等内容，均视为本协议的补充协议，作为本协议不可分割的组成部分，与本协议具有同等法律效力。请您及时关注并阅读前述相关补充协议。</span>
          相关补充协议的部分或全部条款被认定无效的，不影响其它协议或协议条款的效力。
        </div>
        <div class="l-t-title">十四、其他</div>
        <div class="l-text">1. 如本协议中的任何条款无论因何种原因部分或全部无效或不具有执行力，本协议的其余条款仍应有效且具有约束力，您和琴傲信息均应尽力使该条款设定的本意得到实现。</div>
        <div class="l-text">2. 本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。</div>
        <div class="l-text t_bold t-d">3.因本协议所引起的用户与琴傲信息的任何纠纷或争议，双方应尽量友好协商解决；协商不成的，任何一方应向上海市浦东新区人民法院提起诉讼。</div>
        <div class="l-text t_bold t-d">4.您再次确认，已充分阅读并明确知晓本协议中的任何条款，并无条件同意接受本协议约束。对于本协中以粗体加黑的条款已明确知晓内容，并无条件接受该条款约束。</div>
        <div class="l-text">5. 用户如有任何投诉和建议或其他问题可以通过本公司的联系邮箱dannapdf@163.com向本公司反馈。</div>
        <div class="l-text">6. 本协议的首次生效日为2022年07月04日。</div>
        <div class="l-text t_bold" style="text-align: right">
          上海琴傲信息软件有限公司
        </div>
      </div>
    </div>
    <!-- footer -->
    <copy-right-footer />
  </div>
</template>

<script>
import CopyRightFooter from "../../components/CopyRightFooter";
import NavHeader from "../../components/NavHeader";
export default {
  components: {
    CopyRightFooter,
    NavHeader
  }
};
</script>

<style lang="scss" scoped>
.privacy {
  .t-d {
    text-decoration: underline;
  }
  .privacy-header {
    width: 100%;
    min-width: 1200px;
    height: 65px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    .privacy-header-content {
      width: 1200px;
      height: 65px;
      // padding: 0 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 213px;
        height: 40px;
      }
    }
  }
  .privacy-container {
    width: 100%;
    min-width: 1200px;
    padding: 70px 50px 0 50px;
    box-sizing: border-box;
    background: #f6f6f6;
    .container-inner {
      width: 1200px;
      min-width: 1200px;
      background-color: #fff;
      margin: 0 auto;
      padding: 50px 100px 100px;
      box-sizing: border-box;
      .t_bold {
        font-weight: bold;
        color: #000000 !important;
      }
      .l-title {
        font-size: 32px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 25px;
        text-align: center;
        font-family: Microsoft YaHei UI;
      }
      .l-version {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 50px;
        color: #666666;
        text-align: center;
        font-family: Microsoft YaHei UI;
      }
      .l-t-title {
        font-size: 16px;
        font-weight: 600;
        font-family: Microsoft YaHei UI;
        margin-bottom: 20px;
        margin-top: 40px;
      }
      .l-text {
        line-height: 30px;
        color: #666;
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        text-indent: 2em;
      }
    }
  }
}
</style>
